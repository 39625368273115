
.metadata-sidebar .metadata-sidebar-content {
    display: flex;
    flex-direction: column;
    height: 95%;
    overflow-y: auto;
}

.metadata-sidebar-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.metadata-sidebar-title svg {
    cursor: pointer;
}

.metadata-sidebar .arn {
    font-size: 11px;
}

.metadata-sidebar-content .tab-menu .tab-item {
    cursor: pointer;
    padding: 0.3rem;
    border-radius: 5px;
}

.metadata-sidebar-content .tab-menu .tab-item.active {
    background-color: #2c932c;
    color: white;
}
