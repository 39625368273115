.cy {
    position: relative;
    width: auto;
    height: 100%;
    right: 0px;
    z-index: 8;
}

h1 {
    opacity: 1;
    color: #555;
    font-size: 15px;
    font-weight: bold;
    padding-top: 5px;
}
        
/* The sidepanel menu */
.sidepanel {
    height: auto; /* Specify a height */
    width: 290px; /* 0 width - change this with JavaScript */
    position: absolute; /* Stay in place */
    z-index: 1000; /* Stay on top */
    float: left;
    top: auto;
    left: 0;
    background-color: #b7ffff; /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 10px; /* Place content 10px from the top */
    padding-bottom: 10px; /* Place content 10px from the bottom */
    transition: 0s; /* 0.5 second transition effect to slide in the sidepanel */
}

table{
    margin-left: 4px;
    margin-right: auto;
    table-layout: fixed;
}        

/* The sidepanel links */
table td {
    padding: 0px 8px 8px 8px;
    text-decoration: none;
    font-size: 13px;
    color: #555;
    transition: 0.3s;
    vertical-align: middle;          
}

/* Style the button that is used to open the sidepanel */
.button {
    font-size: 15px;
    color: #555;
    cursor: pointer;
    background-color: #b7ffff;
    padding: 5px 10px;
    border: none;
    margin-bottom: 5px;
}

.textField {
    padding-left: 5px;
}

.checkbox {
    margin-left: 0px;
}        

.button:hover {
    background-color: #0f0;
}

.btn-group-sm>.btn, .btn-sm { 
    font-size: 13px;
    background-color: #7d8991;
    border-color: #7d8991;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
    color: #fff;
    background-color: #60717d;
    border-color: #60717d; /*set the color you want here*/
}  

.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active, .btn-outline-primary.active, .open>.dropdown-toggle.btn-primary {
    color: #fff;
    background-color: #60717d;
    border-color: #60717d; /*set the color you want here*/
}         

.form-control-sm { 
    font-size: 13px
}

.custom-select-sm {
    font-size: 13px
} 

.custom-control-label {
    padding-top: 2px;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #7d8991;
    background-color: #7d8991;
}   

.grid {
    width: 100%;
    height: 100%;
}

.diagram-container {
    height: 100%;
}

.loading {
    background: #000;
}

.loading-overlay {
    position: fixed;
    top: 0;
    z-index: 10000;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(80, 80, 80, 0.5);
}

.no-elements-dialog {
}