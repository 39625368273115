.snack-bar {
    max-width: 25%;
    max-height: 200px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.snack-bar.error {
    color: #f44336;
}

.snack-bar.warning {
    color: #ff9800;
}