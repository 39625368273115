.board-actions {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    background: #fff;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    gap: 1.3rem;
    height: 44px;
    z-index: 90;
    transition: all 0.2s ease 0s;
    overflow: hidden;
    justify-content: flex-end;
    width: 132px;
}

.board-actions h4 {
    margin-top: 0.5rem;
}