.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip-text {
    background-color: black;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    font-size: 0.7rem;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
}

.tooltip-container .children * {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; 
    max-width: 15rem;
}

.tooltip-text::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}