.no-avatar-image {
    --avatar-content: 'A';
    background-color: green;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 20px;
    border-radius: 100%; 
    position: relative;
}

.no-avatar-image::after {
    content: var(--avatar-content);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}