input[type='text'] {
  background: #dadada;
  border: 1px solid #aaaaaa;
  border-radius: 5px;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 400px;
}

.input-bg-white {
  background: #fff !important;
  border: inherit !important;
  border-radius: 5px !important;
}

.no-arrows {
  -moz-appearance: textfield;
}

.no-arrows::-webkit-inner-spin-button,
.no-arrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
