.board-layout {
    width: 100%;
    height: 100vh;
}

.logo-button {
    width: 40px;
    height: 40px;
}

.board-layout main {
    overflow: hidden;
    position: relative;
    height: 100%;
}

.board-layout .top-bar {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    width: 100vw;
    z-index: 20;
}

.board-layout .top-bar .bar {
    background: #fff;
    border-radius: 0.4rem;
    padding: 0.5rem;
    display: flex;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    flex-direction: row;
    gap: 1rem;
}


.board-layout .top-bar .bar .skeleton-loader {
    width: 400px;
}

.board-layout .top-bar .bar.right {
}

.board-layout .top-bar .bar.left {

}

.sidebar {
    position: absolute;
    height: calc(100vh - 10rem);
    top: 7rem;
    background: #ffffff;
    border-left: 1px solid #e5e7eb;
    width: 400px;
    padding: 0.5rem;
    z-index: 50;
    border-radius: 0.4rem;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}


.sidebar.left {
    left: 1rem;
}

.sidebar.right {
    right: 1rem;
}
