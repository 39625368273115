.attribute-name {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.resource-button {
    background: #fafafa;
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 5px;
    margin-top: 10px;
}

.text-field h4 {
    flex: 1;
}
.text-field p {
    flex: 2;
}
