.context-menu-item {
    text-align: left;
    padding: 0.1rem;
    border-radius: 5px;
}

.context-menu-item:hover {
    background-color: #f6f6f6;
}

.context-menu-dialog .items {
    display: flex;
    flex-direction: column;
}